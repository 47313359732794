import _map from "lodash/map";
import useSentry from "../hooks/useSentry";

export const mountForTemplate = (mounts: any) => {
  _map(mounts, (importValue: React.Component, mountName: string) => {
    window[mountName] = useSentry(importValue);
  });
};

export default mountForTemplate;
