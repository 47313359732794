import * as Sentry from "@sentry/react";
import _get from "lodash/get";
import React, { useEffect } from "react";

export const useSentry =
  (Component) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/display-name, react/prop-types
  ({ user, environment, sentryTags: tags, ...props }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      Sentry.init({
        dsn: "https://758097ed11fe47c8a9071f3b5b6cbe64@o45111.ingest.sentry.io/97134",
        environment,
        beforeSend(event, hint) {
          console.log("event", event, hint);
          // Check if it is an exception, and if so, show the report dialog
          // not sure if we want dialog yet. Maybe after cleaning up a lot of our errors (otherwise it will show very often)
          // if (event.exception) {
          //   Sentry.showReportDialog({ eventId: event.event_id });
          // }
          return event;
        },
      });
      Sentry.setUser(user);
      Sentry.setTags({
        mounted_react_component: _get(Component, "name"),
        ...tags,
      });
    }, []);
    return (
      <Sentry.ErrorBoundary fallback={"An uncaught error has occured"}>
        <Component {...props} />
      </Sentry.ErrorBoundary>
    );
  };

export default useSentry;
